import React, { useState } from 'react'
import { marked } from 'marked';

const MarkdownPreviewer = () => {
    const[markDown,setMarkDown] = useState('Hello, Write your thing here')

    const handleChange = (e) => {
        setMarkDown(e.target.value);
    }

  return (
    <div className="markdown-previewer">
      <textarea 
        value={markDown} 
        onChange={handleChange} 
        className="input-area"
      />
      <div 
        className="preview-area" 
        dangerouslySetInnerHTML={{ __html: marked(markDown) }} 
      />
    </div>
  )
}

export default MarkdownPreviewer