
import './App.css';
import MarkdownPreviewer from './MarkdownPreviewer';

function App() {
  return (
    <div style={{padding:'40px'}}>
      <h1 style={{textAlign:'center'}}>React Markdown App</h1>
      <MarkdownPreviewer />
    </div>
      
  );
}

export default App;
